import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import styles from "./404.module.styl"

export default function NotFound() {
  return (
    <Layout>
      <div className={styles.main}>
        <h1>404</h1>
        <p>Oops, we couldn't find this page!</p>
        <Link to="/" className="link--home">
          Back To Home
        </Link>
      </div>
    </Layout>
  )
}
